<template>
    <div class="blogger-trip-preview-card" v-if="bloggerTrip && bloggerTrip.trip && translates && translates[langUrl]">
        <div 
            class="blogger-trip-preview-card__main-picture"
            :style="`background-image: url(${ imageSrc(bloggerTrip.trip.pictures[bloggerTrip.trip.mainPictureIndex]) })` "
        >
            <div class="blogger-trip-preview-card__main-picture__opacity"></div>
            <div class="blogger-trip-preview-card__content">
                <div class="blogger-trip-preview-card__content__status">
                    {{ translates[langUrl].status[lang] }} 
                    <span :class="`blogger-trip-preview-card__content__status__value ${bloggerTrip.status}`">{{ statusTitle }}</span>
                    <!-- <span class="blogger-trip-preview-card__content__status__value approved">Одобрен</span> -->
                </div>
                <div class="blogger-trip-preview-card__content__title">{{ bloggerTrip.trip.name }}</div>
                <BaseButton
                    class="blogger-trip-preview-card__content__button button-fill"
                    @click="open( bloggerTrip._id )"
                >{{ translates[langUrl].button_detail[lang] }}</BaseButton>
            </div>
        </div>
        <div class="blogger-trip-preview-card__info">
            <!-- <div class="blogger-trip-preview-card__info__detail">
                <div class="blogger-trip-preview-card__info__detail__key">Результаты опроса</div>
                <div class="blogger-trip-preview-card__info__detail__value">
                    {{ count }} <span class="comment">Квалиф. ответов</span>
                </div>
            </div> -->
            <div class="blogger-trip-preview-card__info__detail">
                <div class="blogger-trip-preview-card__info__detail__key">{{ translates[langUrl].dates[lang] }}</div>
                <div class="blogger-trip-preview-card__info__detail__value">{{ dates }}</div>
            </div>
            <div class="blogger-trip-preview-card__info__detail">
                <div class="blogger-trip-preview-card__info__detail__key">{{ translates[langUrl].duration[lang] }}</div>
                <div class="blogger-trip-preview-card__info__detail__value">
                    {{ duration }} {{ lastDigitToWord(duration) }}
                </div>
            </div>
            <div class="blogger-trip-preview-card__info__detail">
                <div class="blogger-trip-preview-card__info__detail__key">{{ translates[langUrl].price[lang] }}</div>
                <div class="blogger-trip-preview-card__info__detail__value">
                    <span v-html="priceFormated((bloggerTrip.price + bloggerTrip.bloggerAward), currency)"></span>
                </div>
            </div>
            <div class="blogger-trip-preview-card__info__detail">
                <div class="blogger-trip-preview-card__info__detail__key">{{ translates[langUrl].bloggerAwardForPlace[lang] }}</div>
                <div class="blogger-trip-preview-card__info__detail__value">
                    <span v-html="priceFormated(bloggerTrip.bloggerAward, currency)"></span>
                </div>
            </div>
            <div class="blogger-trip-preview-card__info__detail">
                <div class="blogger-trip-preview-card__info__detail__key">{{ translates[langUrl].bloggerAwardTota[lang] }}</div>
                <div class="blogger-trip-preview-card__info__detail__value">
                    <span v-html="priceFormated((bloggerTrip.bloggerAward * bloggerTrip.bloggerPlaces), currency)"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from '@/plugins/moment';
    import { mapState } from 'vuex';

    import store from '@/store';
    import { imageSrc, priceFormated, lastDigitToWord, dateFormated, shortDateFormated } from '@/helpers'; 
    import { BLOGGER_TRIP_STATUS_TITLE } from '@/vars';
    import BaseButton from '../BaseButton.vue';

    export default {
        name: 'BloggerTripPreviewCard',
        components: {
            BaseButton
        },
        props: {
            bloggerTrip: {
                type: Object,
                default: () => {},
            },
            count: {
                type: String,
                default: '-'
            }
        },
        data: () => ({
            imageSrc,
            lastDigitToWord,
            dateFormated,
            shortDateFormated,
            priceFormated,
            BLOGGER_TRIP_STATUS_TITLE,
            popupDownload: false,
            langUrl: '/trips_bloggerPreviewCard'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            statusTitle() {
                return BLOGGER_TRIP_STATUS_TITLE[this.bloggerTrip.status];
            },
            // dates() {
            //     if(this.bloggerTrip && this.bloggerTrip.start && this.bloggerTrip.finish) {
            //         return `${ moment(this.bloggerTrip.start).format('DD MMM') } - ${ moment(this.bloggerTrip.finish).format('DD MMM') }`;
            //     }
            //     return '';
            // },
            dates() {
                if(this.bloggerTrip && this.bloggerTrip.start && this.bloggerTrip.finish) {
                    return shortDateFormated(this.bloggerTrip.start) + ' - ' + shortDateFormated(this.bloggerTrip.finish);
                }
                return '';
            },
            duration() {
                if(this.bloggerTrip && this.bloggerTrip.start && this.bloggerTrip.finish) {
                    return  moment(this.bloggerTrip.finish).diff(moment(this.bloggerTrip.start), 'days')+1;
                }
                return 0;
            },
            currency() {
                return process.env.VUE_APP_DEFAULT_CURRENCY;
            }
        },

        methods: {
            async open(id) {
                await this.$router.push({ name: 'blogger-trip', params: { id } });
            },
            async setStatus(id, status) {
                await this.$emit('setStatus', { id, status });
            },
            authorization() {
                if(!this.user._id) {
                    this.$root.$emit('popupLoginShow');
                }
            },
        }
    }


</script>

<style lang="scss">
.blogger-trip-preview-card {
	display: flex;
	flex-direction: column;
	width: calc(100% - 40px);
    margin: 0 20px 60px;
	overflow: hidden;
    
    @media all and (max-width: 1400px) {
        margin: 0 auto 20px;
    }
    &__main-picture {
        position: relative;
        width: 100%;
        height: 280px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 20px;
        overflow: hidden;
        @media all and (max-width: 768px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        &__opacity {
            position: absolute;
            width: 100%;
            height: 100%;
            background: #000000;
            opacity: 0.3;
            z-index: 100;
        }
    }

    &__content {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 200;
        @media all and (max-width: 768px) {
            padding: 30px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }

        &__status {
            position: absolute;
            top: 40px;
            left: 40px;
            color: #FFFFFF;
            font-size: 14px;
            z-index: 1;
            @media all and (max-width: 768px) {
                position: unset;
            }
            &__value {
                color: #FFAA5F;
                background: rgba(201, 98, 8, 0.32);
                border-radius: 4px;
                font-weight: 700;
                font-size: 14px;
                line-height: 140%;
                padding: 4px 8px;
                &.bloger_moderated {
                    color: #FFAA5F;
                    background: rgba(201, 98, 8, 0.32);
                }
                &.organizer_moderated,
                &.moderated {
                    color: #9ABDFF;
                    background: rgba(28, 80, 177, 0.32);
                }
                &.active {
                    color: #0DDE65;
                    background: rgba(39, 174, 96, 0.32);
                }
                &.declined {
                    color: #FF5C5C;
                    background: rgba(226, 70, 70, 0.32);
                }
            }
        }

        &__title{
            position: absolute;
            bottom: 40px;
            left: 40px;
            font-weight: 700;
            font-size: 38px;
            line-height: 140%;
            letter-spacing: -0.02em;
            margin-bottom: 4px;
            color: #FFFFFF;
            max-width: 600px;
            text-overflow: ellipsis;
            z-index: 1;
            @media all and (max-width: 768px) {
                position: unset;
                font-size: 24px;
                width: calc(100% - 40px);
                text-align: center;
            }
        }

        &__button {
            position: absolute;
            bottom: 40px;
            right: 40px;
            z-index: 1;
            @media all and (max-width: 768px) {
                position: unset;
                width: calc(100% - 40px);
                margin: 0 auto;
            }
        }
    }

    &__info {
        display: flex;
        flex-direction: row;
        padding: 40px;
        @media all and (max-width: 768px) {
            flex-direction: column;
            padding: 0;
            margin: 0 20px 20px 20px;
            box-shadow: 0px 4px 22px rgba(44, 47, 56, 0.05);
            border-radius: 0px 0px 12px 12px;
        }

        &__detail {
            width: 310px;
            padding: 0 20px;
            border-right: 1px #EDEDED solid;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media all and (max-width: 768px) {
                padding: 20px;
                width: 100%;
                border-right: none;
                border-bottom: 1px #EDEDED solid;
            }
            &:last-of-type {
                margin-right: 0;
                border: none;
            }
            &__key {
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                margin-bottom: 8px;
                color: #A1A4B1;
            }
            &__value {
                font-weight: 600;
                font-size: 18px;
                .comment {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    color: #A1A4B1;

                }
            }
        }
    }
}
</style>